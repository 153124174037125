import {
  api as tableApi,
  configs as tableConfigs,
} from '../../table/hooks/useApi'
import useFwWingstopSalesbyOrderMode, {
  fwWingstopFlashSalesByOrderModeConfigs,
} from './useFwWingstopSalesbyOrderMode'
import useFwWingstopTimeClock, {
  fwWingstopTimeClockConfigs,
} from './useFwWingstopTimeClock'
import useLfrBusinessReviewAndGuest, {
  lfrBusinessReviewAndGuestConfigs,
} from './useLfrBusinessReviewAndGuest'
import useMadeFromScratchCsv, {
  madeFromScratchConfigs,
} from './useMadeFromScratch'

export type IApiKeyType = keyof typeof api

export const configs = {
  ...tableConfigs,
  lfrBusinessReviewAndGuest: lfrBusinessReviewAndGuestConfigs,
  fwWingstopTimeClock: fwWingstopTimeClockConfigs,
  fwWingstopFlashSalesByOrderMode: fwWingstopFlashSalesByOrderModeConfigs,
  madeFromScratch: madeFromScratchConfigs,
}

const api = {
  ...tableApi,
  lfrBusinessReviewAndGuest: useLfrBusinessReviewAndGuest,
  fwWingstopTimeClock: useFwWingstopTimeClock,
  fwWingstopFlashSalesByOrderMode: useFwWingstopSalesbyOrderMode,
  madeFromScratch: useMadeFromScratchCsv,
}

const useApi = (apiName: IApiKeyType) => api[apiName]()

export default useApi
