import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { useVariables } from '../../../variables'
import { IApiType } from '../../types'

const query = gql`
  query listLocationItemByDaypart(
    $iStartDate: Date!
    $iEndDate: Date!
    $iQueryType: String!
    $iFilter: JSON!
  ) {
    listLocationItemByDaypart(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iQueryType: $iQueryType
      iFilter: $iFilter
    ) {
      nodes {
        locationName
        itemOrCategoryName
        earlyBird
        breakfast
        lunch
        afternoonSnack
        dinner
        eveningSnack
      }
    }
  }
`

const locationGroupQuery = gql`
  query listLocationGroupItemByDaypart(
    $iStartDate: Date!
    $iEndDate: Date!
    $iQueryType: String!
    $iFilter: JSON!
  ) {
    listLocationItemByDaypart: listLocationGroupItemByDaypart(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iQueryType: $iQueryType
      iFilter: $iFilter
    ) {
      nodes {
        locationName: locationGroupName
        itemOrCategoryName
        earlyBird
        breakfast
        lunch
        afternoonSnack
        dinner
        eveningSnack
      }
    }
  }
`

export const itemByDaypartConfigs = {
  columnFilterName: 'itemByDaypartColumnFilter',
  locationName: 'string',
  itemOrCategoryName: 'string',
  'earlyBird.percent': 'percent',
  'earlyBird.quantity': 'number',
  'breakfast.percent': 'percent',
  'breakfast.quantity': 'number',
  'lunch.percent': 'percent',
  'lunch.quantity': 'number',
  'afternoonSnack.percent': 'percent',
  'afternoonSnack.quantity': 'number',
  'dinner.percent': 'percent',
  'dinner.quantity': 'number',
  'eveningSnack.percent': 'percent',
  'eveningSnack.quantity': 'number',
} as const

const useItemByDaypart = (): IApiType => {
  const { variables } = useVariables()
  const length = variables?.items?.value?.[0].length
  const selectedItem = variables?.items?.value?.[0]?.[length - 1]
  const { startDate, endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const isCategory = `${selectedItem}`.indexOf('category') === 0
  const isSubcategory = `${selectedItem}`.indexOf('subcategory') === 0
  const iFilter = {
    location_group_ids: groupFilter?.ids,
    metrics: ['quantity_sold'],
  }

  if (isCategory || isSubcategory) {
    iFilter.item_category_ids = [parseInt(selectedItem.split('-')[1])]
  } else {
    iFilter.item_ids = [selectedItem]
  }

  const { data, loading } = useQuery(hasGroupBy ? query : locationGroupQuery, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iQueryType: isCategory || isSubcategory ? 'CATEGORY' : 'ITEM',
      iFilter,
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo(() => {
      const listLocationItemByDaypart = data?.listLocationItemByDaypart
        .nodes as
        | {
            locationName: string
          }[]
        | undefined

      if (!listLocationItemByDaypart) return null

      return listLocationItemByDaypart.map((n) => {
        return {
          ...n,
          id: n.locationName,
          parentId: 'root',
        }
      })
    }, [groupFilter, data]),
    loading,
  }
}

export default useItemByDaypart
