import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { INavParams } from 'pared/Routes/navParams'
import { track } from 'pared/analytics/guest'
import { feature, page } from 'pared/analytics/user'
import useLocationInfo from 'pared/components/LocationInfo/hooks/useLocationInfo'
import { DAYPARTS } from 'pared/constants'
import { getBrandSettings } from 'pared/customer'
import getDateRanges, { IDateRange } from 'pared/data/getDateRanges'
import useDefaultLocation from 'pared/hooks/useDefaultLocation'
import { toUsdString } from 'pared/utils/number'
import { scrollToTop } from 'pared/utils/web'

import Main from './Main'
import {
  LIST_LOCATION_GUEST_COMMENTS,
  LIST_LOCATION_GUEST_OPENTABLE_KPIS,
  LIST_LOCATION_OPS_COMPS_BREAKDOWN_BY_REASON,
  LIST_LOCATION_SMG_KPIS,
  LOCATION_GUEST_KPIS,
} from './gql'
import useGuestConfig from './hooks/useGuestConfig'
import { IEmployeeInfoType, IGuestReview } from './types'

interface IProps {
  navParams: INavParams
}

export interface IOpsCompsByReason {
  opsCompsReason: string
  quantity: number
  totalCompsAmount: string
}

export interface IGuestKpi {
  reviewSource: string
  ratingCount?: number
  ratingScore: string
  ratingRank?: string
  locationCount?: number
}

export interface IGuestOpentablKpi {
  ratingScore: string
  ratingCount: string
  ratingScoreChange: string
  seatedCovers: string
  seatedCoversChange: string
  opentableCovers: string
  opentableCoversChange: string
  yourNetworkCovers: string
  yourNetworkCoversChange: string
  phoneCovers: string
  phoneCoversChange: string
  walkinCovers: string
  walkinCoversChange: string
  noShowRate: string
  noShowRateChange: string
  ratingRank?: string
}

function Guest({ navParams }: IProps) {
  const brandSettings = getBrandSettings()
  const guestConfig = useGuestConfig(brandSettings.brand)
  useDefaultLocation()
  const storeId = navParams.storeId
  const dateRange = navParams.dateRange || ''

  const storeIdNum = parseInt(storeId || '0', 10) || 0
  const locationInfo = useLocationInfo(storeIdNum)
  const locationId = locationInfo?.id || null

  const [employeeDayPart, setEmployeeDayPart] = useState<string | null>(
    DAYPARTS.DINNER,
  )
  const [selectedDateRange, setSelectedDateRange] = useState<IDateRange | null>(
    null,
  )

  const [
    listOpsCompsByReason,
    {
      loading: listOpsCompsByReasonLoading,
      error: listOpsCompsByReasonError,
      data: listOpsCompsByReasonData,
    },
  ] = useLazyQuery(LIST_LOCATION_OPS_COMPS_BREAKDOWN_BY_REASON)

  const [
    listGuestKpi,
    {
      loading: listGuestKpiLoading,
      error: listGuestKpiError,
      data: listGuestKpiData,
      variables: listGuestKpiVariables,
    },
  ] = useLazyQuery(LOCATION_GUEST_KPIS)

  const [
    listLocationSmgKpis,
    { loading: listLocationSmgKpisLoading, data: listLocationSmgKpisData },
  ] = useLazyQuery(LIST_LOCATION_SMG_KPIS(brandSettings.brand))

  const [
    listGuestReviews,
    {
      loading: listGuestReviewsLoading,
      error: listGuestReviewsError,
      data: listGuestReviewsData,
      variables: listGuestReviewsVariables,
    },
  ] = useLazyQuery(LIST_LOCATION_GUEST_COMMENTS)

  const [
    listLocationGuestOpentableKpis,
    {
      loading: listLocationGuestOpentableKpisLoading,
      data: listLocationGuestOpentableKpisData,
    },
  ] = useLazyQuery(LIST_LOCATION_GUEST_OPENTABLE_KPIS)

  useEffect(() => {
    async function fetchData() {
      const newAllDateRangeData = await getDateRanges()

      const years = newAllDateRangeData.years

      if (Array.isArray(years) && years.length > 0) {
        let newSelectedDateRange: IDateRange | null = null
        if (dateRange) {
          newSelectedDateRange = newAllDateRangeData.dateRangeMap[dateRange]
        }

        if (!newSelectedDateRange) {
          newSelectedDateRange = newAllDateRangeData.defaultPeriod
        }

        if (locationId && newSelectedDateRange) {
          setSelectedDateRange(newSelectedDateRange)

          const periodStartDate = _.get(
            newSelectedDateRange,
            'startDateStr',
            '',
          )
          const periodEndDate = _.get(newSelectedDateRange, 'endDateStr', '')

          listOpsCompsByReason({
            variables: {
              iLocationId: locationId,
              iStartDate: periodStartDate,
              iEndDate: periodEndDate,
            },
          })
          listGuestKpi({
            variables: {
              iLocationId: locationId,
              iStartDate: periodStartDate,
              iEndDate: periodEndDate,
            },
          })

          if (guestConfig.smg?.isVisible || guestConfig.smgMwb?.isVisible) {
            listLocationSmgKpis({
              variables: {
                iStartDate: periodStartDate,
                iEndDate: periodEndDate,
                iFilter: {
                  location_ids: [locationId],
                },
              },
            })
          }

          listGuestReviews({
            variables: {
              iLocationId: locationId,
              iStartDate: periodStartDate,
              iEndDate: periodEndDate,
            },
          })

          if (guestConfig.opentable?.isVisible) {
            listLocationGuestOpentableKpis({
              variables: {
                iFilter: {
                  location_ids: [locationId],
                },
                iStartDate: periodStartDate,
                iEndDate: periodEndDate,
              },
            })
          }
        }
      }
    }

    fetchData()
  }, [locationId, dateRange])

  useEffect(() => {
    if (navParams.pageUrl) {
      scrollToTop()
      page.visit(navParams.pageUrl)
      feature.used('Guest')
    }
  }, [navParams.pageUrl])

  const onSelectEmployeeDayPart = (event: any) => {
    setEmployeeDayPart(event.target.value)
    track.employeeDayPartChanged(event.target.value)
  }

  let opsCompsByReasons: IOpsCompsByReason[] = []

  if (
    listOpsCompsByReasonData &&
    listOpsCompsByReasonData.listLocationOpsCompsBreakdownByReason &&
    Array.isArray(
      listOpsCompsByReasonData.listLocationOpsCompsBreakdownByReason.nodes,
    )
  ) {
    const rawListOpsCompsByReasonData = _.get(
      listOpsCompsByReasonData,
      'listLocationOpsCompsBreakdownByReason.nodes',
      [],
    )

    opsCompsByReasons = _.map(rawListOpsCompsByReasonData, (d) => {
      return {
        opsCompsReason: _.get(d, 'opsCompsReason', ''),
        quantity: _.get(d, 'quantity', 1),
        totalCompsAmount: toUsdString(_.get(d, 'totalCompsAmount', 0) / 100),
      }
    })
  }

  let guestKpis: IGuestKpi[] = []

  if (Array.isArray(listGuestKpiData?.locationGuestKpis.nodes)) {
    const rawGuestKpis = _.get(listGuestKpiData, 'locationGuestKpis.nodes', [])
    const smgKpis = _.get(
      listLocationSmgKpisData,
      'listLocationSmgKpis.nodes[0]',
      {},
    )
    guestKpis = _.map(rawGuestKpis, (k) => {
      const reviewSource = _.get(k, 'reviewSource', '')
      const ratingScore = _.get(k, 'ratingScore', 0)

      return {
        reviewSource,
        ratingCount: _.get(k, 'ratingCount', 0),
        ratingScore: ratingScore?.toFixed?.(1) || '-',
        ratingRank: _.get(k, 'ratingRank', 0),
        locationCount: _.get(k, 'locationCount', 0),
      }
    })

    guestKpis.push(
      {
        reviewSource: 'dissatisfaction',
        ratingScore: smgKpis.dissatisfactionPercent?.toFixed(1),
        ratingCount: smgKpis.totalSurveys,
      },
      {
        reviewSource: 'inaccurate',
        ratingScore: smgKpis.inaccuratePercent?.toFixed(1),
        ratingCount: smgKpis.totalSurveys,
      },
      {
        reviewSource: 'overallSatisfaction',
        ratingScore: smgKpis.overallSatisfaction?.toFixed(1),
        ratingCount: smgKpis.totalSurveys,
      },
      {
        reviewSource: 'orderNotOnTime',
        ratingScore: smgKpis.orderNotOnTime?.toFixed(1),
        ratingCount: guestConfig.smgMwb
          ? smgKpis.totalOutstandingSurveys
          : smgKpis.totalSurveys,
      },
      {
        reviewSource: 'tasteOfFood',
        ratingScore: smgKpis.tasteOfFood?.toFixed(1),
        ratingCount: guestConfig.smgMwb
          ? smgKpis.totalOutstandingSurveys
          : smgKpis.totalSurveys,
      },
    )
  }

  if (
    listGuestKpiLoading ||
    listLocationSmgKpisLoading ||
    listGuestKpiVariables?.iLocationId !== locationId
  ) {
    guestKpis = [
      {
        ratingScore: 'loading',
        reviewSource: 'acr',
        ratingRank: 'loading',
      },
      {
        ratingScore: 'loading',
        reviewSource: 'momentfeed',
        ratingRank: 'loading',
      },
      {
        ratingScore: 'loading',
        reviewSource: 'google',
        ratingRank: 'loading',
      },
      {
        ratingScore: 'loading',
        reviewSource: 'facebook',
        ratingRank: 'loading',
      },
      {
        ratingScore: 'loading',
        reviewSource: 'delivery',
        ratingRank: 'loading',
      },
      {
        ratingScore: 'loading',
        reviewSource: 'yelp',
        ratingRank: 'loading',
      },
      {
        ratingScore: 'loading',
        reviewSource: 'all',
        ratingRank: 'loading',
      },
    ]
  }

  let guestReviews: IGuestReview[] = []

  if (Array.isArray(listGuestReviewsData?.listLocationGuestComments?.nodes)) {
    const rawGuestReviews = _.get(
      listGuestReviewsData,
      'listLocationGuestComments.nodes',
      [],
    )

    guestReviews = _.map(rawGuestReviews, (r) => {
      const originalDate = _.get(r, 'date', '')
      const formattedDate = moment(originalDate).fromNow()

      return {
        reviewSource: _.get(r, 'reviewSource', ''),
        reviewContent: _.get(r, 'reviewContent', ''),
        ratingScore: _.get(r, 'ratingScore', 0) || 0,
        originalDate,
        formattedDate,
        employeesOnDuty: _.get(r, 'employeesOnDuty', null),
      }
    }).filter((review) => {
      if (!guestConfig.yelp.isVisible) {
        if ((review.reviewSource || '').trim().toLowerCase() === 'yelp') {
          return false
        }
      }
      return true
    })
  }

  let guestOpentableKpis: IGuestOpentablKpi = {
    ratingScore: '-',
    ratingCount: 'No Rating',
    ratingScoreChange: '-',
    seatedCovers: '-',
    seatedCoversChange: '-',
    opentableCovers: '-',
    opentableCoversChange: '-',
    yourNetworkCovers: '-',
    yourNetworkCoversChange: '-',
    phoneCovers: '-',
    phoneCoversChange: '-',
    walkinCovers: '-',
    walkinCoversChange: '-',
    noShowRate: '-',
    noShowRateChange: '-',
    ratingRank: '-',
  }

  if (
    Array.isArray(
      listLocationGuestOpentableKpisData?.listLocationGuestOpentableKpis.nodes,
    )
  ) {
    const rawGuestCovers = _.get(
      listLocationGuestOpentableKpisData,
      'listLocationGuestOpentableKpis.nodes',
      [],
    )

    if (rawGuestCovers.length > 0) {
      const ratingCount = rawGuestCovers?.[0].ratingCount || 0
      guestOpentableKpis = {
        ratingScore: rawGuestCovers?.[0].ratingScore
          ? rawGuestCovers?.[0].ratingScore.toFixed(1)
          : '-',
        ratingCount: ratingCount ? `${ratingCount} Ratings` : 'No Ratings',
        ratingScoreChange: rawGuestCovers?.[0].ratingScoreChange
          ? `${rawGuestCovers?.[0].ratingScoreChange.toFixed?.(1)}% YoY`
          : '-',
        seatedCovers: rawGuestCovers?.[0].seatedCovers
          ? rawGuestCovers?.[0].seatedCovers.toLocaleString()
          : '-',
        seatedCoversChange: rawGuestCovers?.[0].seatedCoversChange
          ? `${rawGuestCovers?.[0].seatedCoversChange.toFixed?.(1)}% YoY`
          : '-',
        opentableCovers: rawGuestCovers?.[0].opentableCovers
          ? rawGuestCovers?.[0].opentableCovers.toLocaleString()
          : '-',
        opentableCoversChange: rawGuestCovers?.[0].opentableCoversChange
          ? `${rawGuestCovers?.[0].opentableCoversChange.toFixed?.(1)}% YoY`
          : '-',
        yourNetworkCovers: rawGuestCovers?.[0].yourNetworkCovers
          ? rawGuestCovers?.[0].yourNetworkCovers.toLocaleString()
          : '-',
        yourNetworkCoversChange: rawGuestCovers?.[0].yourNetworkCoversChange
          ? `${rawGuestCovers?.[0].yourNetworkCoversChange.toFixed?.(1)}% YoY`
          : '-',
        phoneCovers: rawGuestCovers?.[0].phoneCovers
          ? rawGuestCovers?.[0].phoneCovers.toLocaleString()
          : '-',
        phoneCoversChange: rawGuestCovers?.[0].phoneCoversChange
          ? `${rawGuestCovers?.[0].phoneCoversChange.toFixed?.(1)}% YoY`
          : '-',
        walkinCovers: rawGuestCovers?.[0].walkinCovers
          ? rawGuestCovers?.[0].walkinCovers.toLocaleString()
          : '-',
        walkinCoversChange: rawGuestCovers?.[0].walkinCoversChange
          ? `${rawGuestCovers?.[0].walkinCoversChange.toFixed?.(1)}% YoY`
          : '-',
        noShowRate: rawGuestCovers?.[0].noShowRate
          ? `${rawGuestCovers?.[0].noShowRate.toFixed?.(1)}%` || '-'
          : '-',
        noShowRateChange: rawGuestCovers?.[0].noShowRateChange
          ? `${rawGuestCovers?.[0].noShowRateChange.toFixed?.(1)}% YoY`
          : '-',
        ratingRank: rawGuestCovers?.[0]?.ratingRank || '-',
      }
    }
  }

  if (listLocationGuestOpentableKpisLoading) {
    guestOpentableKpis = {
      ratingScore: 'loading',
      ratingCount: 'loading',
      ratingScoreChange: 'loading',
      seatedCovers: 'loading',
      seatedCoversChange: 'loading',
      opentableCovers: 'loading',
      opentableCoversChange: 'loading',
      yourNetworkCovers: 'loading',
      yourNetworkCoversChange: 'loading',
      phoneCovers: 'loading',
      phoneCoversChange: 'loading',
      walkinCovers: 'loading',
      walkinCoversChange: 'loading',
      noShowRate: 'loading',
      noShowRateChange: 'loading',
      ratingRank: 'loading',
    }
  }

  return (
    <Main
      navParams={navParams}
      employeeDayPart={employeeDayPart}
      onSelectEmployeeDayPart={onSelectEmployeeDayPart}
      opsCompsByReasons={opsCompsByReasons}
      guestKpis={guestKpis}
      selectedDateRange={selectedDateRange}
      selectedLocation={locationInfo}
      guestReviews={guestReviews}
      isGuestReviewsLoading={
        listGuestReviewsLoading ||
        listGuestReviewsVariables?.iLocationId !== locationId
      }
      guestOpentableKpis={guestOpentableKpis}
    />
  )
}

export default Guest
