import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { BRAND_ID, BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { getBusinessLabel } from 'pared/customer'
import useBrands from 'pared/layouts/hooks/useBrands'

import { useDateFilter } from '../../dateFilter'
import { IApiDataType } from '../types'

interface IQueryDataType {
  listDirectors: {
    nodes: {
      employeeId: number
      locationGroupId: number
      firstName: string
      lastName: string
    }[]
  }
  listLocationDetails: {
    nodes: {
      id: number
      code: string
      name: string
      locationGroups: {
        id: number
        name: string
        type: string
        start_ate: string
        end_date: string
      }[]
    }[]
  }
  getLocationAccessGroupsForBrand: {
    nodes: {
      id: number
      name: string
      type: string
    }[]
  }
}

interface IQueryVariablesType {
  iFilter: {
    location_group_ids: number[]
    brand_ids: [number]
  }
  iStartDate?: string
  iEndDate?: string
  iBrandId: number
}

const query = gql`
  query corporateGroupFilter(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $iBrandId: Int!
  ) {
    listDirectors(iFilter: $iFilter) {
      nodes {
        employeeId
        locationGroupId
        firstName
        lastName
      }
    }

    listLocationDetails: listLocationDetailsV2(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        id
        code
        name
        locationGroups
      }
    }

    getLocationAccessGroupsForBrand: getLocationAccessGroupsForBrandV2(
      iBrandId: $iBrandId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        id
        name
        type
      }
    }
  }
`

const LOCAITON_GROUP_TYPES: Record<string, string[]> = {
  bibibop: [
    'Comp or Non-Comp',
    'District Manager',
    'Region',
    'Market',
    'Multi-Unit Manager',
  ],
}
const DISABLE_GROUP_BY_LINK = ['ghai_pop', 'bibibop', 'fw_wingstop']
const HAS_COMPANY = ['mwb']

export const useCorporateGroupFilterQuery = (
  variables: IQueryVariablesType,
  skip: boolean,
) =>
  useQuery<IQueryDataType, IQueryVariablesType>(query, {
    variables,
    skip,
  })

const useCorporateGroupFilter = () => {
  const { brand } = useBrands()
  const directorLabel = getBusinessLabel('director')
  const { startDate, endDate } = useDateFilter()
  const { data, loading } = useCorporateGroupFilterQuery(
    {
      iFilter: {
        location_group_ids: [BRAND_LOCATION_GROUP_ID[brand]],
        brand_ids: [BRAND_ID[brand]],
      },
      iStartDate: startDate,
      iEndDate: endDate,
      iBrandId: BRAND_ID[brand],
    },
    !startDate || !endDate,
  )

  return {
    data: useMemo((): IApiDataType => {
      if (!data) return null

      const locationAccessGroupsForBrand = (
        data?.getLocationAccessGroupsForBrand?.nodes || []
      ).filter(
        (d) => d.type !== 'Brand' && d.type !== 'Custom' && d.type !== 'null',
      )
      // FIXME: should use locationAccessGroupsForBrand for all brands
      const locationGroups =
        locationAccessGroupsForBrand.length !== 0
          ? locationAccessGroupsForBrand.filter(
              (l) => LOCAITON_GROUP_TYPES[brand]?.includes(l.type) ?? true,
            )
          : data.listDirectors.nodes.map((director) => ({
              id: director.locationGroupId,
              name: `${director.firstName} ${director.lastName}`,
              type: directorLabel,
            }))
      const locationGroupTypes = locationGroups.reduce(
        (result, { type }) =>
          result.includes(type) ? result : [...result, type],
        [] as string[],
      )

      const allStores = data.listLocationDetails.nodes.map((l) => {
        const directors = data.listDirectors.nodes
          .filter((d) =>
            l.locationGroups.some((lg) => lg.id === d.locationGroupId),
          )
          .sort((a, b) => {
            const locationGroupA = l.locationGroups.find(
              (lg) => lg.id === a.locationGroupId,
            )
            const locationGroupB = l.locationGroups.find(
              (lg) => lg.id === b.locationGroupId,
            )

            if (!locationGroupA || !locationGroupB) return 0

            return moment
              .utc(locationGroupA.end_date)
              .isSameOrBefore(moment.utc(locationGroupB.end_date))
              ? 1
              : -1
          })
        const director = directors[0]
        const locationGroup = locationGroups.find(
          (lg) => lg.id === director?.locationGroupId,
        )

        return {
          id: l.id,
          name: `${l.code} - ${l.name}`,
          header: 'Stores',
          link: `/${brand}/store_detail?store=${l.id}`,
          groupBy: director && {
            id: director.employeeId,
            header: locationGroup?.type || directorLabel,
            name: `${director.firstName} ${director.lastName}`,
            link:
              !DISABLE_GROUP_BY_LINK.includes(brand) &&
              `/${brand}/employee_profile/${director.employeeId}`,
          },
        }
      })

      const values = [
        ...(HAS_COMPANY.includes(brand)
          ? [
              {
                id: 'Brand Breakdown',
                parentId: 'root',
                ids: [BRAND_LOCATION_GROUP_ID[brand]],
                label: 'Company',
              },
            ]
          : []),
        {
          id: 'all-stores',
          parentId: 'root',
          ids: [BRAND_LOCATION_GROUP_ID[brand]],
          label: 'All Stores',
          list: allStores,
        },
        ...locationGroupTypes.map((locationGroupType) => [
          {
            id: locationGroupType,
            parentId: 'root',
            label: locationGroupType,
          },
          {
            id: `${locationGroupType} Breakdown`,
            parentId: locationGroupType,
            label: `Breakdown By ${locationGroupType}`,
            ids: locationGroups
              .filter((l) => l.type === locationGroupType)
              .map((d) => d.id),
            list: locationGroups
              .filter((l) => l.type === locationGroupType)
              .map((d) => {
                const director = data.listDirectors.nodes.find(
                  (l) => l.locationGroupId === d.id,
                )

                return {
                  id: d.id,
                  name: d.name,
                  header: d.type,
                  link:
                    director &&
                    !DISABLE_GROUP_BY_LINK.includes(brand) &&
                    `/${brand}/employee_profile/${director.employeeId}`,
                }
              }),
          },
        ]),
        locationGroups.map(({ id, type, name }) => ({
          id: id.toString(),
          parentId: type,
          ids: [id],
          label: name,
          list: data.listLocationDetails.nodes
            .filter((l) => l.locationGroups.some((lg) => lg.id === id))
            .map((l) => {
              const director = data.listDirectors.nodes.find((d) =>
                l.locationGroups.some((lg) => lg.id === d.locationGroupId),
              )
              const locationGroup = locationGroups.find(
                (lg) => lg.id === director?.locationGroupId,
              )

              return {
                id: l.id,
                name: `${l.code} - ${l.name}`,
                header: 'Stores',
                link: `/${brand}/store_detail?store=${l.id}`,
                groupBy: director && {
                  id: director.employeeId,
                  header: locationGroup?.type || directorLabel,
                  name: `${director.firstName} ${director.lastName}`,
                  link:
                    !DISABLE_GROUP_BY_LINK.includes(brand) &&
                    `/${brand}/employee_profile/${director.employeeId}`,
                },
              }
            }),
        })),
      ].flat()

      return {
        values:
          locationGroupTypes.length !== 1
            ? values
            : values
                .filter(({ id }) => id !== locationGroupTypes[0])
                .map(({ parentId, ...value }) => ({
                  ...value,
                  parentId:
                    parentId === locationGroupTypes[0] ? 'root' : parentId,
                })),
        defaultValue: [values[0].id],
      }
    }, [data, brand, directorLabel]),
    loading,
  }
}

export default useCorporateGroupFilter
