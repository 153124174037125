import useAllStores, { IDataType as IAllStoresDataType } from './useAllStores'
import useCorporateGroup, {
  IDataType as ICorporateGroupDataType,
} from './useCorporateGroup'
import useDate, { IDataType as IDateDataType } from './useDate'
import useInterval, { IDataType as IIntervalDataType } from './useInterval'
import useItemByDaypartColummFilter, {
  IDataType as IItemByDaypartColumnFilterType,
} from './useItemByDaypartColumnFilter'
import useItemOrCategory, {
  IDataType as IItemOrCategoryDataType,
} from './useItemOrCategory'
import useTimeBetween, {
  IDataType as ITimeBetweenDataType,
} from './useTimeBetween'
import useTrack, { IDataType as ITrackDataType } from './useTrack'

export type IVariablesType =
  | 'track'
  | 'date'
  | 'allStores'
  | 'corporateGroup'
  | 'items'
  | 'interval'
  | 'timeBetween'
  | 'itemByDaypartColumnFilter'
export type IDataType = IDateDataType &
  IAllStoresDataType &
  ITrackDataType &
  ICorporateGroupDataType &
  IItemOrCategoryDataType &
  IIntervalDataType &
  ITimeBetweenDataType &
  IItemByDaypartColumnFilterType

export const hooks = [
  ['track', useTrack],
  ['date', useDate],
  ['allStores', useAllStores],
  ['corporateGroup', useCorporateGroup],
  ['items', useItemOrCategory],
  ['interval', useInterval],
  ['timeBetween', useTimeBetween],
  ['itemByDaypartColumnFilter', useItemByDaypartColummFilter],
]
