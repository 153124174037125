import useItemByDaypart, { itemByDaypartConfigs } from './useItemByDaypart'
import useLocationGroups, { locationGroupsConfigs } from './useLocationGroups'
import useLocations, { locationsConfigs } from './useLocations'
import useMadeFromScratch, {
  madeFromScratchConfigs,
} from './useMadeFromScratch'
import useMadeFromScratchSummary, {
  madeFromScratchSummaryConfigs,
} from './useMadeFromScratchSummary'
import useSystemwideOverview, {
  systemwideOverviewConfigs,
} from './useSystemwideOverview'

export const configs = {
  systemwideOverview: systemwideOverviewConfigs,
  locationGroups: locationGroupsConfigs,
  locations: locationsConfigs,
  itemByDaypart: itemByDaypartConfigs,
  madeFromScratch: madeFromScratchConfigs,
  madeFromScratchSummary: madeFromScratchSummaryConfigs,
}

export const api = {
  systemwideOverview: useSystemwideOverview,
  locationGroups: useLocationGroups,
  locations: useLocations,
  itemByDaypart: useItemByDaypart,
  madeFromScratch: useMadeFromScratch,
  madeFromScratchSummary: useMadeFromScratchSummary,
}
